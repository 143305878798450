import * as React from "react"
import Seo from '../components/Seo/Seo';
import {Layout} from '../components/Layout';
import {ContactBanner, ContactHero} from '../components';

export default function Kontakt () {
  return (
    <Layout>
      <Seo title="Strona główna" />
      <ContactBanner />
      <ContactHero />
    </Layout>
  )
}